import request from '@/utils/request'

export default {
  createClient(data) {
    return request({
      url: '/client',
      method: 'post',
      data
    })
  },
  editClient(data) {
    return request({
      url: `/client/${data.clientId}`,
      method: 'put',
      data
    })
  },
  fetchClientList(data) {
    return request({
      url: '/client',
      method: 'get',
      params: data,
    })
  },
  fetchClientInfo(data) {
    return request({
      url: `/client/${data.clientId} `,
      method: 'get',
      params: data,
    })
  },
}
