export default {
  en: {
    taskId: 'Task ID',
    taskName: 'Task Name',
    downloadUrl: 'Download URL',
    createTime: 'Create Time',
    delete: 'Delete',
    suspend: 'Suspend',
    created: 'Created',
    processing: 'Processing',
    completed: 'Completed',
    terminated: 'Terminated',
    failed: 'Failed',
    download: 'Download'
  },
  zh: {
    taskId: '任务ID',
    taskName: '任务名称',
    downloadUrl: '下载链接',
    createTime: '创建时间',
    delete: '删除',
    suspend: '终止',
    created: '创建成功',
    processing: '执行中',
    completed: '已完成',
    terminated: '已终止',
    failed: '执行失败',
    download: '下载中心'
  }
}