import request from '@/utils/request'

export default {
  // 客户类型 client
  createClientType(data) {
    return request({
      url: '/type/client',
      method: 'post',
      data
    })
  },
  editClientType(data) {
    return request({
      url: `/type/client/${data.typeId}`,
      method: 'put',
      data
    })
  },
  fetchClientTypeInfo(data) {
    return request({
      url: `/type/client/${data.typeId}`,
      method: 'get',
      params: data
    })
  },
  fetchClientTypeList(data) {
    return request({
      url: `/type/client`,
      method: 'get',
      params: data
    })
  },
  // 面料类型 fabric
  createFabricType(data) {
    return request({
      url: '/type/fabric',
      method: 'post',
      data
    })
  },
  editFabricType(data) {
    return request({
      url: `/type/fabric/${data.typeId}`,
      method: 'put',
      data
    })
  },
  fetchFabricTypeInfo(data) {
    return request({
      url: `/type/fabric/${data.typeId}`,
      method: 'get',
      params: data
    })
  },
  fetchFabricTypeList(data) {
    return request({
      url: `/type/fabric`,
      method: 'get',
      params: data
    })
  },
  // 工艺类型 process
  createProcessType(data) {
    return request({
      url: '/type/process',
      method: 'post',
      data
    })
  },
  editProcessType(data) {
    return request({
      url: `/type/process/${data.typeId}`,
      method: 'put',
      data
    })
  },
  fetchProcessTypeInfo(data) {
    return request({
      url: `/type/process/${data.typeId}`,
      method: 'get',
      params: data
    })
  },
  fetchProcessTypeList(data) {
    return request({
      url: `/type/process`,
      method: 'get',
      params: data
    })
  },
  // 尺寸类型 size
  createSizeType(data) {
    return request({
      url: '/type/size',
      method: 'post',
      data
    })
  },
  editSizeType(data) {
    return request({
      url: `/type/size/${data.typeId}`,
      method: 'put',
      data
    })
  },
  fetchSizeTypeInfo(data) {
    return request({
      url: `/type/size/${data.typeId}`,
      method: 'get',
      params: data
    })
  },
  fetchSizeTypeList(data) {
    return request({
      url: `/type/size`,
      method: 'get',
      params: data
    })
  },
}
