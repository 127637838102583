<template>
  <div class="">
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#222222"
      text-color="#B1B1B1"
      active-text-color="#FFFFFF"
      menu-trigger="hover"
      router>
      <template v-for="navItem in navList">
        <el-menu-item v-if="navItem.subMenu.length === 0" :key="navItem.path" :index="navItem.path">{{
          navItem.label
        }}</el-menu-item>
        <el-submenu v-else :index="navItem.path" :key="navItem.path">
          <template slot="title">{{ navItem.label }}</template>
          <el-menu-item v-for="subNavItem in navItem.subMenu" :index="subNavItem.path" :key="subNavItem.path">{{
            subNavItem.label
          }}</el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  watch: {},
  mounted() {},
  computed: {
    navList() {
      const isAdmin = this.$store.getters.role === 'admin'
      const adminMenuList = [
        {
          label: this.$t('navbar.account'),
          path: '/account',
          subMenu: [
            {
              label: this.$t('navbar.accountManagement'),
              path: '/account/account',
              subMenu: []
            },
            {
              label: this.$t('navbar.roleManagement'),
              path: '/account/role',
              subMenu: []
            }
            // { // 暂时废弃
            //   label: this.$t('navbar.authorityManagement'),
            //   path: '/account/authority',
            //   subMenu: []
            // }
          ]
        },
        {
          label: this.$t('navbar.factors'),
          path: 'factors',
          subMenu: [
            {
              label: this.$t('navbar.fabricManagement'),
              path: '/factors/fabric',
              subMenu: []
            },
            {
              label: this.$t('navbar.processManagement'),
              path: '/factors/process',
              subMenu: []
            },
            {
              label: this.$t('navbar.sizeManagement'),
              path: '/factors/size',
              subMenu: []
            }
          ]
        },
        {
          label: this.$t('navbar.client'),
          path: 'client',
          subMenu: [
            {
              label: this.$t('navbar.clientList'),
              path: '/client/list',
              subMenu: []
            }
          ]
        },
        {
          label: this.$t('navbar.order'),
          path: 'order',
          subMenu: [
            {
              label: this.$t('navbar.createOrder'),
              path: '/order/create',
              subMenu: []
            },
            {
              label: this.$t('navbar.orderList'),
              path: '/order/list',
              subMenu: []
            },
            {
              label: this.$t('navbar.sumOrderList'),
              path: '/order/sumList',
              subMenu: []
            },
            {
              label: this.$t('navbar.preOrderList'),
              path: '/order/preList',
              subMenu: []
            },
            // {
            //   label: this.$t('navbar.orderDetail'),
            //   path: '/order/detail',
            //   subMenu: []
            // }
          ]
        },
        {
          label: this.$t('navbar.production'),
          path: 'production',
          subMenu: [
            {
              label: this.$t('navbar.producerManagement'),
              path: '/production/producer',
              subMenu: []
            }
          ]
        }
      ]
      const userMenuList = [
        {
          label: this.$t('navbar.order'),
          path: 'order',
          subMenu: [
            {
              label: this.$t('navbar.createOrder'),
              path: '/order/create',
              subMenu: []
            },
            {
              label: this.$t('navbar.orderList'),
              path: '/order/list',
              subMenu: []
            },
            {
              label: this.$t('navbar.sumOrderList'),
              path: '/order/sumList',
              subMenu: []
            }
            // {
            //   label: this.$t('navbar.orderDetail'),
            //   path: '/order/detail',
            //   subMenu: []
            // }
          ]
        }
      ]
      return isAdmin ? adminMenuList : userMenuList
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
