import user from './user'
import account from './account'
import factors from './factors'
import client from './client'
import order from './order'
import type from './type'
import upload from './upload'
import download from './download'
import production from './production'

export default {
  ...user,
  ...account,
  ...factors,
  ...client,
  ...order,
  ...type,
  ...upload,
  ...download,
  ...production
}
