import request from '@/utils/request'

export default {
  taskExistUnread(data) {
    return request({
      url: '/task/exist/unread',
      method: 'get',
      params: data
    })
  },
  fetchTaskList(data) {
    return request({
      url: '/task/list',
      method: 'get',
      params: data,
    })
  },
  cancelTask(data) {
    return request({
      url: `/task/cancel/${data.taskId}`,
      method: 'put',
      data
    })
  },
  deleteTask(data) {
    return request({
      url: `/task/delete/${data.taskId}`,
      method: 'put',
      data
    })
  },
  signTask(data) { // 标记已读
    return request({
      url: `/task/read/${data.taskId}`,
      method: 'put',
      data
    })
  }
}
