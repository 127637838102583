export default {
  zh: {
    clientId: '客户ID',
    clientName: '客户名称',
    clientType: '客户类型',
    clientCode: '客户唛头',
    clientManager: '客户经理',
    contactPerson: '联系人',
    account: '客户登陆账号',
    latestEditTime: '最近编辑时间(编辑者)',
    clientTypeManagement: '客户类型管理',
    password: '客户登陆密码',
    phone: '电话',
    email: '邮箱',
    contactAddress: '联系地址',
    changePassword: '更改密码',
    clientTypeId: '客户类型ID',
    createClient: '创建客户',
    editClient: '编辑客户',
    createClientType: '创建客户类型',
    editClientType: '编辑客户类型',
  },
  en: {
    clientId: 'Client ID',
    clientName: 'Client Name',
    clientType: 'Client Type',
    clientCode: 'Client Code',
    clientManager: 'Client Manager',
    contactPerson: 'Contact Person',
    account: 'Client Login Account',
    latestEditTime: 'Latest Edit Time (Editor)',
    clientTypeManagement: 'Client Type Management',
    password: 'Client Login Password',
    phone: 'Phone Number',
    email: 'E-Mail Address',
    contactAddress: 'Contact Address',
    changePassword: 'Change Password',
    clientTypeId: 'Client Type ID',
    createClient: 'Create Client',
    editClient: 'Edit Client',
    createClientType: 'Create Client Type',
    editClientType: 'Edit Client Type',
  }
}