import Layout from '@/layout/Layout'

const factorsRouter = {
  path: '/factors',
  component: Layout,
  name: 'Factors',
  redirect: '/factors/fabric',
  meta: {
    title: 'factors',
    icon: 'factors'
  },
  children: [
    {
      path: 'fabric',
      component: () => import('@/views/factors/fabric'),
      name: 'FabricManagement',
      meta: { title: 'fabricManagement' },
    },
    {
      path: 'fabric/type',
      component: () => import('@/views/factors/fabricType'),
      name: 'FabricTypeManagement',
      meta: { title: 'fabricTypeManagement' },
    },
    {
      path: 'process',
      component: () => import('@/views/factors/process'),
      name: 'ProcessManagement',
      meta: { title: 'processManagement' },
    },
    {
      path: 'process/type',
      component: () => import('@/views/factors/processType'),
      name: 'ProcessTypeManagement',
      meta: { title: 'processTypeManagement' },
    },
    {
      path: 'size',
      component: () => import('@/views/factors/size'),
      name: 'SizeManagement',
      meta: { title: 'sizeManagement' },
    },
    {
      path: 'size/type',
      component: () => import('@/views/factors/sizeType'),
      name: 'SizeTypeManagement',
      meta: { title: 'sizeTypeManagement' },
    }
  ]
}
export default factorsRouter
