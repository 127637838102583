import request from '@/utils/request'

export default {
  createRole(data) { // 新增角色
    return request({
      url: '/permission/role',
      method: 'post',
      data
    })
  },
  editRole(data) { // 编辑角色
    return request({
      url: `/permission/role/${data.roleId}`,
      method: 'put',
      data
      // data
    })
  },
  fetchPermissionList(data) { // 获取权限列表
    return request({
      url: '/permission',
      method: 'get',
      data
    })
  },
  fetchRoleList(data) { // 获取角色列表
    return request({
      url: '/permission/role',
      method: 'get',
      params: data
      // data
    })
  },
  fetchRoleInfo(data) { // 获取角色详情
    return request({
      url: `/permission/role/${data.roleId}`,
      method: 'get',
      params: data
      // data
    })
  },
  // status(data) { // 测试用
  //   return request({
  //     url: `/status`,
  //     method: 'get',
  //     params: data
  //     // data
  //   })
  // }
}