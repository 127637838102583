export default {
  zh: {
    // 生产商
    producerID: '生产商ID',
    producerName: '生产商名称',
    producerType: '生产商类型',
    producerCode: '生产商编码',
    productionStep: '生产环节',
    contactPerson: '联系人',
    phoneNumber: '联系电话',
    createProducer: '创建生产商',
    editProducer: '编辑生产商',
    mainProducer: '主生产商',
    outsideContractor: '外部承包商',
    colorMatching: '调色',
    makeSamples: '打样',
    photograph: '拍照',
    sendSamples: '寄样',
    printing: '印花',
    sewing: '缝制',
    qualityControl: '质检',
    package: '包装',
    printingStep: '印花环节（包含调色、打样、印花）',
    qualityControlPackage: '质检&包装',
    tailorStep: '缝制'
  },
  en: {
    // 生产商
    producerID: 'Producer ID',
    producerName: 'Producer Name',
    producerType: 'Producer Type',
    producerCode: 'Producer Code',
    productionStep: 'Production Step',
    contactPerson: 'Contact Person',
    phoneNumber: 'Phone Number',
    createProducer: 'Create Producer',
    editProducer: 'Edit Producer',
    mainProducer: 'Main Producer',
    outsideContractor: 'Outside Contractor',
    colorMatching: 'Color Matching',
    makeSamples: 'Make Samples',
    photograph: 'Photograph',
    sendSamples: 'Send Samples',
    printing: 'Printing',
    sewing: 'Sewing',
    qualityControl: 'Quality Control',
    package: 'Package',
    printingStep: 'Printing Step (Include Color Remaining & Make Samples & Printing)',
    qualityControlPackage: 'Quality Control & Package',
    tailorStep: 'Sewing'
  }
}
