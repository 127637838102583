import Vue from 'vue'
import moment from 'moment'

Vue.filter('dateFormat', function (value, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!value) return ''
  return moment(value).format(format)
})

// 生产商类型 1:主生产商 2:外部承包商
Vue.filter('producerType', function (value) {
  if (value === 1) {
    return 'mainProducer'
  }
  return 'outsideContractor'
})
