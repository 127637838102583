import request from '@/utils/request'

export default {
  // fabric 面料
  createFabric(data) {
    return request({
      url: '/factors/fabric',
      method: 'post',
      data
    })
  },
  editFabric(data) {
    return request({
      url: `/factors/fabric/${data.factorId}`,
      method: 'put',
      data
    })
  },
  fetchFabricInfo(data) {
    return request({
      url: `/factors/fabric/${data.factorId}`,
      method: 'get',
      params: data
    })
  },
  fetchFabric(data) {
    return request({
      url: `/factors/fabric`,
      method: 'get',
      params: data
    })
  },
  // process 工艺
  createProcess(data) {
    return request({
      url: '/factors/process',
      method: 'post',
      data
    })
  },
  editProcess(data) {
    return request({
      url: `/factors/process/${data.factorId}`,
      method: 'put',
      data
    })
  },
  fetchProcessInfo(data) {
    return request({
      url: `/factors/process/${data.factorId}`,
      method: 'get',
      params: data
    })
  },
  fetchProcess(data) {
    return request({
      url: `/factors/process`,
      method: 'get',
      params: data
    })
  },
  // size 尺寸
  createSize(data) {
    return request({
      url: '/factors/size',
      method: 'post',
      data
    })
  },
  editSize(data) {
    return request({
      url: `/factors/size/${data.factorId}`,
      method: 'put',
      data
    })
  },
  fetchSizeInfo(data) {
    return request({
      url: `/factors/size/${data.factorId}`,
      method: 'get',
      params: data
    })
  },
  fetchSize(data) {
    return request({
      url: `/factors/size`,
      method: 'get',
      params: data
    })
  },
  // 获取各类型工艺集合  接口用途：新建子单选择工艺页面
  fetchFactorsProcessTypeList(data) {
    return request({
      url: `/factors/process/type/list`,
      method: 'get',
      params: data
    })
  },
  fetchMiYinFabricData(data) {
    return request({
      url: `/factors/miyin/fabric`,
      method: 'get',
      params: data
    })
  }
}
