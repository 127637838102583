import request from '@/utils/request'
import Axios from 'axios'

export default {
  // 上传
  uploadFile(data) {
    return request({
      url: '/file/upload',
      method: 'post',
      data
    })
  },
  // 检查上传状态，是否成功
  uploadCheck(data) {
    return request({
      url: '/file/check',
      method: 'post',
      data
    })
  },
  getImgInfo(url) {
    return Axios.get(`${url}?x-oss-process=image/info`)
  },
  // 图片压缩接口
  imgCompress(url) {
    return request({
      url: '/file/image/compress',
      method: 'get',
      params: { imageUrl: url }
    })
  }
}
