import Cookies from 'js-cookie'
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      const hasRole = store.getters.role
      if (hasRole) {
        if (store.getters.permissionRoutes.length === 0) {
          // 设置路由
          const role = (store.getters && store.getters.role) || Cookies.get('feline-role')
          const accessRoutes = await store.dispatch('permission/generateRoutes', role)
          router.addRoutes(accessRoutes)
          if(accessRoutes.map(item => item.path).indexOf('/account') === -1) { // 外部用户访问
            return next({path: '/order'})
          }
          next({ path: to.path, query: to.query, params: to.params })
        }
        // next({ path: to.path })
        next()
      } else {
        // remove token and go to login page to re-login
        await store.dispatch('user/resetToken')
        Message.error('Role Error')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
