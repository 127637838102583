import Layout from '@/layout/Layout'

const accountRouter = {
  path: '/account',
  component: Layout,
  name: 'Account',
  redirect: '/account/account',
  meta: {
    title: 'account',
    icon: 'account'
  },
  children: [
    {
      path: 'account',
      component: () => import('@/views/account/account'),
      name: 'AccountManagement',
      meta: { title: 'accountManagement' }
    },
    {
      path: 'role',
      component: () => import('@/views/account/role'),
      name: 'RoleManagement',
      meta: { title: 'roleManagement' }
    },
    // { // 权限页面暂时废弃
    //   path: 'authority',
    //   component: () => import('@/views/account/authority'),
    //   name: 'AuthorityManagement',
    //   meta: { title: 'authorityManagement' }
    // },
  ]
}
export default accountRouter
