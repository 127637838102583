<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <span class="language">
      {{ languageLabel }}
      <!-- <i class="el-icon-caret-bottom" /> -->
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language === 'zh'" command="zh"> 中文 </el-dropdown-item>
      <el-dropdown-item :disabled="language === 'en'" command="en"> English </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.getters.language
    },
    languageLabel() {
      return this.$store.getters.language === 'en' ? 'English' : '简体中文'
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.language {
  // color: #ffffff;
  color: rgb(177, 177, 177);
  // color: #606266;
  font-size: 14px;
}
</style>
