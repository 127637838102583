export default {
  zh: {
    orderId: '订单 ID',
    orderStatus: '订单状态',
    orderOwner: '订单归属',
    orderRemark: '订单备注',
    orderCreateTime: '订单创建时间',
    batchExport: '批量导出订单图稿',
    pattern: '花型',
    quantity: '数量',
    latestEditTime: '最后编辑时间',
    all: '全部',
    waitingAccept: '待接单',
    waitingSchedule: '待排产',
    inProduct: '加工中',
    inTransfer: '运输中',
    complete: '已完成', 
    canceld: '已取消',
    accept: '接单',
    cancel: '取消订单',
    detail: '详情',
    schedule: '排产',
    sendOut: '发货',
    signIn: '签收',
    scheduleTips: '是否确认订单已排产？',
    description: '描述',
    barcode: '条码',
    type: '类型',
    size: '尺寸',
    process: '工艺',
    oredrQuantity: '下单数量',
    sendOutQuantity: '发货数量',
    signInQuantity: '签收数量',
    signInRemark: '签收备注',
    logisticCompany: '物流公司',
    logisticNumber: '物流单号',
    sendOutRemark: '发货备注',
    chooseOrderOwner: '选择订单归属',
    clientCode: '客户唛头',
    phoneNumber: '联系电话',
    contactPerson: '联系人',
    contactAddress: '联系地址',
    chooseOrderType: '选择订单类型',
    normalOrder: '普通订单',
    sampleOrder: '样单',
    orderDetail: '订单详情',
    chooseFabric: '选择面料',
    fabricDetail: '面料详情',
    chooseProcess: '选择工艺',
    processDetail: '工艺详情',
    action: '操作',
    addFabric: '添加面料',
    addPattern: '添加花型',
    transportation: '物流方式',
    air: '空运（物流在途7~10天）',
    sea: '海运（物流在途21~30天）',
    other: '其他',
    air0: '空运',
    sea0: '海运',
    other0: '其他',
    downloadPattern: '下载花型',
    downloadPattern2: '下载图稿',
    ignorePatternTip: '忽略图稿修改提示',
    modifyPattern: '修改图稿',
    sendOutQuantityTips: '请输入发货数量',
    signInQuantityTips: '请输入签收数量',
    needClientUId: '请选择订单归属',
    acceptOrder: '接收订单',
    cancelOrder: '取消订单',
    checkForm: '请检查表单',
    noEmpty: "不能为空",
    deliverCode: '发货唛头',
    OBAMB: 'OBAMB',
    OBORH: 'OBORH',
    Other: '其他',
    orderType: '订单类型',
    needPatternPic: '请上传花型',
    subOrderRemark: '备注(仅用于此面料)',
    copyToOtherSubOrderRemark: '拷贝至其他面料',
    expectedReceivingDate: '期望收货日期',
    selectDate: '选择日期',
    formMode: '表单模式',
    chartMode: '图表模式',
    copyOrderInfo: '以文字形式复制订单信息',
    exportOrderInfomation: '导出订单信息表',
    exportOrderDeliveryInvoice: '导出订单发货单',
    pushOrderToMeprint: '推送订单至米印',
    operator: '操作人',
    canPushAgain: '订单可再次推送',
    canNotPushAgain: '不可重复推送',
    factorQuantifier: '款面料',
    patternQuantifier: '张图稿',
    total: '总计',
    quantifier: '条',
    sizeNum: '种尺寸',
    gong: '共',
    chartBlockOrder: '下单',
    chartBlockProduct: '生产',
    chartBlockAccept: '接单',
    chartBlockMeprint: '米印',
    chartBlockDelivery: '交付',
    receipt: '签收',
    pushTime: '推送时间',
    latestDeliveryDate: '最晚发出日期',
    deliveryDate: '发出日期',
    receiptDate: '签收日期',
    viewPushRecord: '查看推送记录',
    finishedSize: '成品尺寸',
    recommendedPrintSize: '建议打印尺寸',
    estimatedFabricConsumption: '预估消耗尺寸',
    patternSize: '图稿尺寸',
    sample: '打样订单',
    quick: '快反订单',
    tiny: '微型订单',
    normal: '中型订单',
    medium: '中型订单',
    large: '大型订单',
    day: '天',
    overTime: '超时时间',
    remainTime: '剩余时间',
    textWaitingRec: '已发货，等待签收',
    textWaitingAcc: '等待接收订单',
    textWaitingSch: '等待排产',
    textWaitingDel: '加工中，等待发货',
    delivery: '发出',
    sortNew: '最新订单排最前',
    sortOld: '最旧订单排最前',
    expectedReceiveTime: '期望收货日期',
    viewProductionOrder: '查看生产单',
    needToConfirm: '产前确认',
    proArr: '生产安排',
    beforeProductionConfirmType0: '无需确认，可直接生产',
    beforeProductionConfirmType1: '需要调色/追色',
    beforeProductionConfirmType2: '需拍照确认后才可生产',
    beforeProductionConfirmType3: '需寄样确认后才可生产',
    newOrNeed: '新图稿 or 需追色早期订单/样单',
    newPattern: '新图稿',
    newToMatch: '追色早期订单/样单',
    mainProducer: '主供应商',
    fabricPrint: '面料/印花',
    tailor: '开料/缝制',
    qc: '质检/包装',
    orderAcceptRecord: '订单接收记录',
    orderAcceptStatus: '订单接收状态',
    acceptOptTime: '接单操作时间',
    waitAudit: '待审批',
    passed: '审核通过',
    rejected: '驳回',
    preOrderReview: '预订单审批',
    preOrderPass: '预订单审批通过',
    preOrderReject: '预订单审批驳回',

    // 弹窗
    uploadPattern: '上传花型',
    uploadNewPattern: '上传新花型',
    recentUploadPattern: '最近上传花型',
    recent30: '最近30天',
    recent3090: '最近30~90天',
    recent90: '最近90天',
    fabric: '面料',
    confirmPattern: '确认图稿',
    volume: "大小",
    format: '类型',
    DPI: 'DPI',
    createTime: '创建时间',
    patternOwner: '花型归属',
    patternDetail: '花型详情',
    needUploadFirst: '请先上传或选择图稿',
    confirmAccept: '确认是否接收订单？',
    confirmCancel: '确认是否取消订单？',
    downloadThumbnail: '下载缩略图',
    downloadOriginalFile: '下载源文件',
    taskCreateSuc: '任务创建成功',
    taskSucDialogContent: '导出任务创建成功，请至下载中心查看进度。',
    viewTask: '查看任务',
    confirm: '确定',
    confirmPushOrder: '确认推送订单',
    checkFailTip: '请检查图稿尺寸',
    patternSuccess: '图稿尺寸正确',
    patternModifyTip: '需修改图稿尺寸',
    patternIgnore: '已忽略修改提示',
    miyinOrderId: '米印订单ID',
    pushTip: '该订单已有推送记录，再次推单将先取消历史推单后再推送，请确认是否再次推单？',
    deliveryType: '订单交付类型',
    latestDeliveryTime: '最晚发出时间',
    orderCreateMonth: '订单下单月份',
    orderDeliveryMonth: '订单交付月份',
    exportOrderSum: '导出订单汇总表',
    totalQuantity: '总数量',
    undelivered: '未交付',
    delivered: '已交付',
    confirmMerge: '确认合并',
    noMerge: '不需合并',
    mergeConfirmTitle: '有多笔子订单可以合并：',
    mergeConfirmText: '该订单有多笔子订单的面料、工艺、尺寸完全相同，是否合并子订单？',
  },
  en: {
    orderId: 'Order ID',
    orderStatus: 'Order Status',
    orderOwner: 'Order Owner',
    orderRemark: 'Order Remark',
    orderCreateTime: 'Order Create Time',
    batchExport: 'Export Order Drawings In Batches',
    pattern: 'Pattern',
    quantity: 'Quantity',
    latestEditTime: 'Latest Edit Time',
    all: 'All',
    waitingAccept: 'Waiting for Accept',
    waitingSchedule: 'Waiting for Schedule',
    inProduct: 'In Production',
    inTransfer: 'In Transit',
    complete: 'Completed', 
    canceld: 'Canceled',
    accept: 'Accept',
    cancel: 'Cancel',
    detail: 'Detail',
    schedule: 'Schedule',
    sendOut: 'Send Out',
    signIn: 'Sign In',
    scheduleTips: 'Confirm that the order has been scheduled?',
    description: 'Description',
    barcode: 'Barcode',
    type: 'Type',
    size: 'Size',
    process: 'Process',
    oredrQuantity: 'Order Quantity',
    sendOutQuantity: 'Send Out Quantity',
    signInQuantity: 'Sign In Quantity',
    signInRemark: 'Sign In Remark',
    logisticCompany: 'Logistic Company',
    logisticNumber: 'Logistic Number',
    sendOutRemark: 'Send Out Remark',
    chooseOrderOwner: 'Choose Order Owner',
    clientCode: 'Client Code',
    phoneNumber: 'Phone Number',
    contactPerson: 'Contact Person',
    contactAddress: 'Contact Address',
    chooseOrderType: 'Choose Order Type',
    normalOrder: 'Normal Order',
    sampleOrder: 'Sample Order',
    orderDetail: 'Order Detail',
    chooseFabric: 'Choose Fabric',
    fabricDetail: 'Fabric Detail',
    chooseProcess: 'Choose Process',
    processDetail: 'Process Detail',
    action: 'Action',
    addFabric: 'Add Fabric',
    addPattern: 'Add Pattern',
    transportation: 'Transportation',
    air: 'Air(7-10 days exclude production time)',
    sea: 'Sea (21~30 days exclude production time)',
    other: 'Other',
    air0: 'Air',
    sea0: 'Sea',
    other0: 'Other',
    downloadPattern: 'Download Pattern',
    downloadPattern2: 'Download Pattern',
    ignorePatternTip: 'Ignore the pattern modification prompts ',
    modifyPattern: 'Modify Pattern',
    sendOutQuantityTips: 'Send out quantity is required',
    signInQuantityTips: 'Sign in quantity is required',
    needClientUId: 'Please Choose The Order Owner First',
    acceptOrder: 'Accept Order',
    cancelOrder: 'Cancel Order',
    checkForm: 'Please check the form',
    fabricLack: 'Fabric Lack',
    processLack: 'Process Lack',
    noEmpty: "Can't be empty",
    deliverCode: 'Deliver Code',
    OBAMB: 'OBAMB',
    OBORH: 'OBORH',
    Other: 'Other',
    orderType: 'Order Delivery Type',
    needPatternPic: 'Please Upload Pattern',
    subOrderRemark: 'Remark(Only for this fabric)',
    copyToOtherSubOrderRemark: 'Copy to other fabrics',
    expectedReceivingDate: 'Expected Receiving Date',
    selectDate: 'Select Date',
    formMode: 'Form Mode',
    chartMode: 'Chart Mode',
    copyOrderInfo: 'Copy Order Information In Text Form',
    exportOrderInfomation: 'Export Order Infomation Form',
    exportOrderDeliveryInvoice: 'Export Order Delivery Form',
    pushOrderToMeprint: 'Push Order To Meprint',
    operator: 'Operator',
    canPushAgain: 'Can be Re-Pushed',
    canNotPushAgain: 'Not Repeatable Push',
    factorQuantifier: 'Fabric | Fabrics',
    patternQuantifier: 'Pattern | Patterns',
    total: 'Total',
    quantifier: 'Pc | Pcs',
    sizeNum: 'Size | Sizes',
    gong: '',
    chartBlockOrder: 'Order',
    chartBlockProduct: 'Production',
    chartBlockAccept: 'Accept',
    chartBlockMeprint: 'Meprint',
    chartBlockDelivery: 'Delivery',
    receipt: 'Receipt',
    pushTime: 'Push Time',
    latestDeliveryDate: 'Latest Delivery Date',
    deliveryDate: 'Delivery Date',
    receiptDate: 'Receipt Date',
    viewPushRecord: 'View Push Record',
    finishedSize: 'Finished Size',
    recommendedPrintSize: 'Recommended Print Size',
    estimatedFabricConsumption: 'Estimated Fabric Consumption',
    patternSize: 'Pattern Size',
    sample: 'Sample Order',
    quick: 'Quick Order',
    tiny: 'Tiny Order',
    normal: 'Normal Order',
    medium: 'Medium Order',
    large: 'Large Order',
    day: 'day | days',
    overTime: 'Over Time',
    remainTime: 'Remaining Time',
    textWaitingRec: 'Delivery Waiting Receipt',
    textWaitingAcc: 'Waiting for Accept Order',
    textWaitingSch: 'Waiting for Schedule',
    textWaitingDel: 'In Production and Waiting for Delivery',
    delivery: 'Delivery',
    sortNew: 'The latest order comes first',
    sortOld: 'The oldest order comes first',
    expectedReceiveTime: 'Expected Receiving Time',
    viewProductionOrder: 'View Production Order',
    needToConfirm: 'Need to confirm before production',
    proArr: 'Production arrangement',
    beforeProductionConfirmType0: 'Produce without confirmation',
    beforeProductionConfirmType1: 'Produce after matching color',
    beforeProductionConfirmType2: 'Produce after sample confirmation by photograph',
    beforeProductionConfirmType3: 'Produce after sample confirmation by mail',
    newOrNeed: 'New Patterns or Need to matching color from early/sample order',
    newPattern: 'New Patterns',
    newToMatch: 'Need to matching color from early/sample order',
    mainProducer: 'Main Producer',
    fabricPrint: 'Fabric/Printing',
    tailor: 'Sewing',
    qc: 'QC/Package',
    orderAcceptRecord: 'Order Accept Record',
    orderAcceptStatus: '订单接收状态',
    acceptOptTime: 'Order Accept Time',
    waitAudit: 'To Be Reviewed',
    passed: 'Passed',
    rejected: 'Rejected',
    preOrderReview: 'Pre-Order Review',
    preOrderPass: 'Pre-Order Approved',
    preOrderReject: 'Pre-Order Rejected',

    // 弹窗
    uploadPattern: 'Upload Pattern',
    uploadNewPattern: 'Upload New Pattern',
    recentUploadPattern: 'Recently Uploaded Pattern',
    recent30: 'Nearly 30 days',
    recent3090: 'Between nearly 30 ~ 90 days',
    recent90: '90 days ago',
    fabric: 'Fabric',
    confirmPattern: 'Confirm Pattern',
    volume: "Volume",
    format: 'Format',
    DPI: 'DPI',
    createTime: 'Create Time',
    patternOwner: 'Pattern Owner',
    patternDetail: 'Pattern Detail',
    needUploadFirst: 'Please Upload Or Choose The Pattern First',
    confirmAccept: 'Confirm whether to accept the order？',
    confirmCancel: 'Confirm whether to cancel the order？',
    downloadThumbnail: 'Download Thumbnail',
    downloadOriginalFile: 'Download Original File',
    taskCreateSuc: 'Task Created Successfully',
    taskSucDialogContent: 'The export task was created successfully.Please go to the download center to check the progress.',
    viewTask: 'View Task',
    confirm: 'Confirm',
    confirmPushOrder: 'Confirm Push Order',
    checkFailTip: 'Please Check The Pattern Size',
    patternSuccess: 'The pattern size is correct',
    patternModifyTip: 'The pattern size need to be modified',
    patternIgnore: 'The modification message was ignored',
    miyinOrderId: 'Miyin Order ID',
    pushTip: 'There has been a push record of this order. If you push the order again, you will cancel the historical push order first and then push it again. Please confirm whether to push the order again.',
    deliveryType: 'Order Delivery Type',
    latestDeliveryTime: 'Latest Delivery Date',
    orderCreateMonth: 'Order Creation Month',
    orderDeliveryMonth: 'Order Delivery Month',
    exportOrderSum: 'Export Order Summary Form',
    totalQuantity: 'Total Quantity',
    undelivered: 'Undelivered',
    delivered: 'Delivered',
    confirmMerge: 'Confirm Merge',
    noMerge: 'No Merge',
    mergeConfirmTitle: 'Multiple sub-orders can be merged:',
    mergeConfirmText: 'This order has multiple sub-orders with the same fabric, process, and size. Do you want to merge the sub-orders?',
  }
}