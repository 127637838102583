export default {
  zh: {
    fabric: '面料',
    fabricCode: '面料编码',
    fabricName: '面料名称',
    fabricType: '面料类型',
    fabricOwner: '面料归属',
    fabricTypeManagement: '面料类型管理',
    fabricDesc: '面料描述',
    fabricPicture: '面料图片',
    width: '门幅',
    fabricTypeId: '面料类型ID',
    AssMiYinFabric: '关联米印面料',
    // 弹窗
    createFabric: '新建面料',
    editFabric: '编辑面料',
    createFabricType: '新建面料类型',
    editFabricType: '编辑面料类型',
    // 工艺
    processName: '工艺名称',
    processType: '工艺类型',
    processOwner: '工艺归属',
    processTypeManagement: '工艺类型管理',
    processDesc: '工艺描述',
    processPicture: '工艺图片',
    processTypeId: '工艺类型ID',
    createProcess: '新建工艺',
    editProcess: '编辑工艺',
    createProcessType: '新建工艺类型',
    editProcessType: '编辑工艺类型',
    processId: '工艺ID',
    // 尺寸
    sizeName: '尺寸',
    sizeType: '类型',
    sizeOwner: '尺寸归属',
    sizeTypeManagement: '尺寸类型管理',
    sizeTypeId: '尺寸类型ID',
    createSize: '新建尺寸',
    editSize: '编辑尺寸',
    createSizeType: '新建尺寸类型',
    editSizeType: '编辑尺寸类型',
    sizeId: '尺寸ID',
    cnName: '面料中文名',
    enName: '面料英文名',
    MeprintAssociate: '关联米印产品',
    length: '长度',
    parallelingPrintingQuantity: '并列印花条数',
  },
  en: {
    fabric: 'Fabric',
    fabricCode: 'Fabric Code',
    fabricName: 'Fabric Name',
    fabricType: 'Fabric Type',
    fabricOwner: 'Fabric Owner',
    fabricTypeManagement: 'Fabric Type Management',
    fabricDesc: 'Fabric Description',
    fabricPicture: 'Fabric Picture',
    width: 'Width',
    fabricTypeId: 'Fabric Type ID',
    AssMiYinFabric: 'Associated With Miprint Fabric',
    // 弹窗
    createFabric: 'Create Fabric',
    editFabric: 'Edit Fabric',
    createFabricType: 'Create Fabric Type',
    editFabricType: 'Edit Fabric Type',
    // 工艺
    processName: 'Process Name',
    processType: 'Process Type',
    processOwner: 'Process Owner',
    processTypeManagement: 'Process Type Management',
    processDesc: 'Process Description',
    processPicture: 'Process Picture',
    processTypeId: 'Process Type ID',
    createProcess: 'Create Process',
    editProcess: 'Edit Process',
    createProcessType: 'Create Process Type',
    editProcessType: 'Edit Process Type',
    processId: 'Process ID',
    // 尺寸
    sizeName: 'Size',
    sizeType: 'Type',
    sizeOwner: 'Size Owner',
    sizeTypeManagement: 'Size Type Management',
    sizeTypeId: 'Size Type ID',
    createSize: 'Create Size',
    editSize: 'Edit Size',
    createSizeType: 'Create Size Type',
    editSizeType: 'Edit Size Type',
    sizeId: 'Size ID',
    cnName: 'Chinese Name of Fabric',
    enName: 'English Name of Fabric',
    MeprintAssociate: 'Associated with Meprint Product',
    length: 'Length',
    parallelingPrintingQuantity: 'Paralleling Printing Quantity',
  }
}