import Layout from '@/layout/Layout'

const productionRouter = {
  path: '/production',
  component: Layout,
  name: 'Production',
  redirect: '/production/producer',
  meta: {
    title: 'production',
    icon: ''
  },
  children: [
    {
      path: 'producer',
      component: () => import('@/views/production/producer'),
      name: 'ProducerManagement',
      meta: { title: 'producerManagement' }
    }
  ]
}
export default productionRouter
