import Layout from '@/layout/Layout'

const accountRouter = {
  path: '/client',
  component: Layout,
  name: 'Client',
  redirect: '/client/list',
  meta: {
    title: 'client',
    icon: 'client'
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/client/list'),
      name: 'ClientList',
      meta: { title: 'clientList' }
    },
    {
      path: 'typeList',
      component: () => import('@/views/client/type'),
      name: 'ClientType',
      meta: { title: 'clientTypeList' }
    }
  ]
}
export default accountRouter
