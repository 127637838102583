import Layout from '@/layout/Layout'

const downloadRouter = {
  path: '/download-center',
  component: Layout,
  name: 'DownloadCenter',
  redirect: '/download-center/index',
  meta: {
    title: 'downloadCenter',
    icon: 'download'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/download-center/index'),
      name: 'downloadTask',
      // meta: { title: 'downloadTask' }
    }
  ]
}
export default downloadRouter
