export default {
  zh: {
    accountId: '账号ID',
    accountName: '账号名称',
    accountType: '账号类型',
    accountRole: '账号角色',
    accountStatus: '状态',
    accountCreationTime: '账号创建时间',
    authorityItem: '权限项',
    authorityPoint: '权限点',
    authorityUrl: '权限链接',
    latestEditTime: '最新编辑时间',
    editor: '编辑人',
    internalUser: '内部用户',
    externalUser: '外部用户',
    // 弹窗
    createAccount: '新建账号',
    editAccount: '编辑账号',
    loginPassword: '登录密码',
    phoneNumber: '电话号码',
    eMailAddress: '邮箱地址',
    createRole: '新建角色',
    editRole: '编辑角色',
    all:'全部',
    authViewOrderStatus:'授权查看订单状态',
  },
  en: {
    accountId: 'Account ID',
    accountName: 'Account Name',
    accountType: 'Account Type',
    accountRole: 'Account Role',
    accountStatus: 'Status',
    accountCreationTime: 'Account Creation Time',
    authorityItem: 'Authority Item',
    authorityPoint: 'Authority Point',
    authorityUrl: 'Authority URL',
    latestEditTime: 'Latest Edit Time',
    editor: 'Editor',
    internalUser: 'Internal User',
    externalUser: 'External User',
    // 弹窗
    createAccount: 'Create Account',
    editAccount: 'Edit Account',
    loginPassword: 'Login Password',
    phoneNumber: 'Phone Number',
    eMailAddress: 'E-Mail Address',
    createRole: 'Create Role',
    editRole: 'Edit Role',
    all:'all',
    authViewOrderStatus:'Authorize to view order status',
  }
}
